.comboBox {
  width: 100%;
}

.borderDropDown {
  border: 1px solid #aaa;

}

.lightgray {
  background-color: red;
}