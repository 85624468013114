.videoLink {
    cursor: pointer;
}

.videoLink:hover {
    background-color: #b1e3ef;
}

.selectedVideo {
    background-color: #cae5f9 !important;
}

.custHeading {
    font-size: 0.95rem;
    font-weight: bold;
    color: #0c46b5b8;
}

.topicList ul li a {
    display: block;
    cursor: pointer;
}

.linkTitle {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    cursor: pointer;
}

.topicList ul li a:hover,
ul li a:focus {
    color: red !important;

}