.right {
    color: green;
}

.wrong {
    color: red;
}

.QuesView p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.rightAnsTitle {
    color: rgb(30 143 2) !important;
    font-size: medium !important;
    margin-bottom: 2px !important;
}