
.ReactPdfViewer {
  margin-bottom: 10px;
}

.ReactPdfViewer input,
.ReactPdfViewer button {
  font: inherit;
  margin-left: 2px;

}

.select {
  margin-left: 2px;
}

i {
  margin-left: 3px;
  cursor: pointer;
  font-size: large;
}

.pdf_header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 6px;
  color: white;
  width: 100%;
  margin-top: 10px;
  text-align: center;


}

.pdf_header h1 {
  font-size: inherit;
  margin: 0;
}

.PDF__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1px 0;
  padding: 1px;


}

.PDF__container__load {
  margin-top: 1em;
  color: white;
}

.PDF__container__document {
  margin-top: 0px;
}

.PDF__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;


}

.PDF__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: .0em;

}



.PDF__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

.pdf_footer {
  background: #ffffff;
  padding-left: 40%;
  padding-bottom: 2px;
}