.form-login-adm {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 100vh;

  > div {
    padding: 22px;
    background-color: #fff;
    border-radius: 7px;
    width: 470px;
    max-width: 470px;
    height: auto;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .form-label {
    margin-bottom: 0.02rem;
    // font-size: 12px;
  }
}
.singupLink{
  color: blue;
  cursor: pointer !important;
}
.input{
  height: 50px !important;
  font-size: 1.1rem !important;
}
.texth3{  
  font-size: 1.1625rem !important;
}
.otpMsg{  
  font-size: .75rem !important;
  color: #096e87;
}