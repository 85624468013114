.table th,
.table td {
    padding: .5rem !important;
}

.btnDis {

    background-color: #e4e1e5;


    display: inline-block;

}

.dispMsg {
    color: rgb(0, 76, 255);
    text-align: center;
    padding-top: 100px !important;
}

.loading {
    color: rgb(139, 42, 7);
    text-align: left;
    padding-top: 100px !important;
}

h5 {
    color: rgb(225 123 63) !important;
    font-size: large !important;
}

.minHeight {
    min-height: 300px !important;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.the-legend {
    border-style: none;
    border-width: 0;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    width: auto;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    background: rgb(233 116 35);
    color: white;
}

.the-fieldset {
    border: 1px solid #e0e0e0;
    padding: 10px;
}

.border {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
}

.subjectReport:hover {
    background: rgb(217 239 237);
}