.testBtn {
  text-decoration: none;
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid white;
  max-width: 80px !important;
}

.btnDis {
  max-width: 80px !important;
  background-color: #e4e1e5;
  color: white;
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid white;
}

.btn-success {
  padding: 4px 8px !important;
}


.saved {
  padding: 8px 12px 8px 12px;
  max-width: 70px !important;
  border-radius: 40%;
  background-color: yellowgreen;
  margin: 2px !important;
  border: white 1px solid;
}

.testBtn:hover {
  background-color: #e0b8ed;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #2594c9;
  color: white;
}

.round {
  padding: 8px 12px 8px 12px;
  max-width: 70px !important;
  border-radius: 40%;
  margin: 2px !important;
  border: white 1px solid;
}

.round:hover {
  background: #f5ac4d;
  color: white;
  border: white 1px solid;
}

.saved:hover {
  background: #f5ac4d;
  color: white;
  border: white 1px solid;
}

.clicked {
  background: #46746c;
  color: white;
  border: white 1px solid;
}

.mx-width {
  max-width: 33.3333333333% !important;
}

.btn-width {
  max-width: 10% !important;
}

.mx-width50 {
  max-width: 50% !important;
}

.custHeading {
  font-size: 0.95rem;
  font-weight: bold;
  color: #0c46b5b8;
}